import { css } from '@emotion/react'
import { Link } from '@mui/material'
import type { DataPolicyAcceptanceSubscriptionUiElement } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { CheckboxContainer, colorTokens, Text, useTranslate } from '@orus.eu/pharaoh'
import { memo, useCallback, useEffect, type ReactNode } from 'react'
import { cguLink, confidentialityLink } from '../../../../lib/static-links'
import { ifStateProxy } from '../if-state-proxy'

export const DataPolicyAcceptanceSubscriptionUiElementBlock = ifStateProxy<DataPolicyAcceptanceSubscriptionUiElement>(
  function DataPolicyAcceptanceSubscriptionUiElementBlock(props) {
    const { uiElement, stateProxy, context } = props
    const value = stateProxy.read(uiElement.dimensions.value)
    const hasAcceptedCommercialMessages = stateProxy.read(uiElement.dimensions.hasAcceptedCommercialMessages)
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)

    const handleMainAcceptChange = useCallback(
      (value?: boolean | null | undefined) => {
        if (value == undefined) return
        stateProxy.write(uiElement.dimensions.value, value)
      },
      [stateProxy, uiElement],
    )

    const handleCommercialPurposeAcceptChange = useCallback(
      (value?: boolean | null | undefined) => {
        if (value == undefined) return
        stateProxy.write(uiElement.dimensions.hasAcceptedCommercialMessages, value)
      },
      [stateProxy, uiElement],
    )

    useEffect(() => {
      if (
        value == undefined &&
        (context === 'backoffice' ||
          ('initializeValueWhenDisplayed' in uiElement && uiElement.initializeValueWhenDisplayed))
      ) {
        handleMainAcceptChange(false)
      }
    }, [handleMainAcceptChange, uiElement, value, context])

    return (
      <>
        {operatingZone === 'es' ? (
          <CommercialPurposeCheckbox
            checked={!!hasAcceptedCommercialMessages}
            onChange={handleCommercialPurposeAcceptChange}
            operatingZone={operatingZone}
          />
        ) : null}
        <LegacyDataPolicyAcceptanceCheckbox
          hasAccepted={!!value}
          setHasAccepted={handleMainAcceptChange}
          type="selfonboarding"
          operatingZone={operatingZone}
        />
      </>
    )
  },
)

const CommercialPurposeCheckbox = memo<{
  checked: boolean
  onChange: (value: boolean) => void
  operatingZone: OperatingZone
}>(function CommercialPurposeCheckbox({ checked, onChange }) {
  return (
    <CheckboxContainer checked={checked} onChange={onChange} size="medium" border>
      <Text variant="body2">
        Acepto recibir información comercial sobre productos del sector seguros, más información{' '}
        <LabelLink url={confidentialityLink['es']}>aquí</LabelLink>
      </Text>
    </CheckboxContainer>
  )
})

type LegacyDataPolicyAcceptanceCheckboxProps = {
  hasAccepted: boolean
  setHasAccepted: (value: boolean) => void
  type: 'backoffice' | 'selfonboarding'
  operatingZone: OperatingZone
}

export const LegacyDataPolicyAcceptanceCheckbox = memo<LegacyDataPolicyAcceptanceCheckboxProps>(
  function LegacyDataPolicyAcceptanceCheckbox({ hasAccepted, setHasAccepted, type, operatingZone }) {
    const translate = useTranslate()
    const generalTermsNode = <LabelLink url={cguLink[operatingZone]}>{translate('general_terms_link_text')}</LabelLink>

    let text: ReactNode

    if (type === 'backoffice') {
      // backoffice not translated
      text = (
        <>
          Le client accepte les {generalTermsNode}, la{' '}
          <LabelLink url={confidentialityLink[operatingZone]}>politique d’utilisation de ses données</LabelLink> et
          consent à être contacté par téléphone par Orus
        </>
      )
    } else if (operatingZone === 'fr') {
      text = (
        <>
          J’accepte les {generalTermsNode}, la{' '}
          <LabelLink url={confidentialityLink[operatingZone]}>politique d’utilisation de mes données</LabelLink> et je
          consens à être contacté par téléphone par Orus
        </>
      )
    } else if (operatingZone === 'es') {
      text = (
        <>
          Acepto las {generalTermsNode}, la{' '}
          <LabelLink url={confidentialityLink[operatingZone]}>política para el uso de mis datos</LabelLink> y consiento
          ser contactado por teléfono por Orus
        </>
      )
    }

    return (
      <CheckboxContainer
        checked={hasAccepted}
        onChange={setHasAccepted}
        size={type === 'backoffice' ? 'small' : 'medium'}
        border={true}
      >
        <Text variant="body2">{text}</Text>
      </CheckboxContainer>
    )
  },
)

const LabelLink = memo<{ url: string; children: ReactNode }>(function LabelLink({ url, children }) {
  return (
    <Link
      css={css`
        color: ${colorTokens['color-text-base-link']};
      `}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(event) => event.stopPropagation()}
    >
      {children}
    </Link>
  )
})
